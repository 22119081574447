import React from "react";
import { Link } from "react-router-dom";
import RangeSelector from "../range-selector/RangeSelector";
import styles from "./NavigationBar.module.scss";

import LogoIMG from "./logo@2x.png";

function NavigationBar(props) {
  return (
    <div className={styles.holder}>
      <div className={styles.innerwrap}>
        <Link to="/" class={styles.logo}>
          <img src={LogoIMG} height={30} width="auto" />
          <span>Analytics</span>
        </Link>
        <RangeSelector />
      </div>
    </div>
  );
}

export default NavigationBar;
