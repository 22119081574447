import React, { useContext } from "react";
import Layout from "../layout";
import { AppConfigContext } from "../../contexts/appConfigProvider";
import styles from "./Dashboard.module.scss";
import DashboardGpm from "../../components/dashboard-gpm/DashboardGPM";
import DashboardBillabilityRatio from "../../components/dashboard-billability-ratio/DashboardBillabilityRatio";
import DashboardFlexibilityRatio from "../../components/dashboard-flexibility-ratio/DashboardFlexibilityRatio";
import DashboardHourlyCosts from "../../components/dashboard-hourly-costs/DashboardHourlyCosts";
import DashboardFreelanceFTECostPerHour from "../../components/dashboard-freelance-fte-cost-per-hour/DashboardFreelanceFTECostPerHour";
import DashboardHoursByQuarter from "../../components/dashboard-hours-by-quarter/DashboardHoursByQuarter";

function Dashboard(props) {
  const [appConfig, appConfigDispatch] = useContext(AppConfigContext);
  //const [{ isLoading, response, error }, doFetch] = useFetch("/api/gpm.php");

  return (
    <Layout>
      <h1>Dashboard overview</h1>

      <div className={styles.holder}>
        <DashboardGpm />
        <DashboardBillabilityRatio />
        <DashboardFlexibilityRatio />

        <DashboardHoursByQuarter />
        <DashboardHourlyCosts />
        <DashboardFreelanceFTECostPerHour />
      </div>
    </Layout>
  );
}

export default Dashboard;
