import React from "react";
import styles from "./InfoCard.module.scss";
import { NavLink } from "react-router-dom";
import LoadingIndicator from "../LoadingIndicator";

function InfoCard({
  isLoading,
  title,
  headComponent,
  graphComponent,
  moreInfoLink,
}) {
  return (
    <div className={styles.infocard_wrap}>
      <h2 className={styles.infocard_title}>{title}</h2>
      <div className={styles.holder}>
        {isLoading && (
          <div className={styles.loading_indicator}>
            <LoadingIndicator />
          </div>
        )}
        {!isLoading && (
          <React.Fragment>
            <div className={styles.head_holder}>{headComponent}</div>
            <div className={styles.graph_holder}>{graphComponent}</div>
            {moreInfoLink && (
              <div className={styles.moreinfo_wrap}>
                <NavLink to={moreInfoLink}>More details</NavLink>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export default InfoCard;
