import React, { useEffect } from "react";
import InfoCard from "../infocard/InfoCard";
import useFetch from "../../hooks/useFetch";
import { Bar } from "react-chartjs-2";
import ChartLegendItem from "../chart-legend-item/ChartLegendItem";

function DashboardHoursByQuarter() {
  const [{ isLoading, response, error }, doFetch] = useFetch(
    "/api/hours-by-quarter.php"
  );

  const headerComponent = () => {
    return (
      <>
        <ChartLegendItem
          labelText="Client hours"
          labelColor={global.config.colors.primaryBlue}
          extraText="Current Client hrs:"
          extraNumeric="-,---"
          extraNumericColor={global.config.colors.textGreen}
        />
        <ChartLegendItem
          labelText="Internal hours"
          labelColor={global.config.colors.secondaryBlue}
          extraText="Current Internal hrs:"
          extraNumeric="---"
          extraNumericColor={global.config.colors.textMagenta}
        />
      </>
    );
  };

  const graphComponent = () => {
    if (!response) return <></>;
    const data = {
      labels: response.labels,
      datasets: [
        {
          label: "Client hours",
          stack: "Stack 0",
          fill: false,
          borderColor: global.config.colors.primaryBlue,
          backgroundColor: global.config.colors.primaryBlue,
          data: response.client_hours,
          lineTension: 0,
        },
        {
          label: "Internal hours",
          stack: "Stack 0",
          fill: false,
          borderColor: global.config.colors.secondaryBlue,
          backgroundColor: global.config.colors.secondaryBlue,
          data: response.internal_hours,
          lineTension: 0,
        },
      ],
    };

    const options = {
      chartArea: {
        backgroundColor: "rgba(255,255,255,1)",
      },
      layout: {
        padding: 0,
      },
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        x: {
          display: true,
          grid: {
            display: true,
            drawOnChartArea: false,
          },
          ticks: {
            fontSize: 20,
          },
        },
        y: {
          display: true,
          grid: {
            drawBorder: false,
          },
          scaleLabel: {
            display: false,
            labelString: "%",
          },
          ticks: {
            callback: function (value, index, values) {
              return value.toFixed(0);
            },
            padding: 5,
            fontSize: 8,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    };

    console.log("Building with:", data, options);

    return <Bar width={350} height={170} data={data} options={options} />;
  };

  useEffect(() => {
    console.log("Fetching GPM data");
    doFetch();
  }, []);

  return (
    <InfoCard
      title="Hours by Quarter"
      isLoading={isLoading || !response || error}
      headComponent={headerComponent()}
      graphComponent={graphComponent()}
      moreInfoLink="/"
    />
  );
}

export default DashboardHoursByQuarter;
