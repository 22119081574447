import React from "react";
import Layout from "../layout";

function GPM(props) {
  return (
    <Layout>
      <h1>GPM page</h1>
    </Layout>
  );
}

export default GPM;
