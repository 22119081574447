import Routes from "./routes";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
// import { CurrentUserProvider } from "./contexts/currentUserProvider";
// import TopBar from "./components/topBar";
// import MainFooter from "./components/mainFooter";
import "./App.scss";
import {AppConfigProvider} from "./contexts/appConfigProvider";
// import Layout from "./pages/layout";

function App() {
		return (
				<>
						<AppConfigProvider>
								<Router>
										<Routes />
								</Router>
						</AppConfigProvider>
				</>
		);
}

export default App;
