import React, { createContext, useReducer } from "react";

const initialState = {
  startYear: 2020,
  startQuarter: 1,
  endYear: 2021,
  endQuarter: 1,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_DATE_RANGE":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const AppConfigContext = createContext();

export const AppConfigProvider = ({ children }) => {
  const value = useReducer(reducer, initialState);
  return (
    <AppConfigContext.Provider value={value}>
      {children}
    </AppConfigContext.Provider>
  );
};
