import React from "react";
import NavigationBar from "../components/navibation-bar/NavigationBar";

function Layout({ children }) {
  return (
    <main className="main-wrapper">
      <NavigationBar />
      <div className="content-holder">{children}</div>
    </main>
  );
}

export default Layout;
