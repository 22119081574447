module.exports = global.config = {
  colors: {
    primaryBlue: "#0E94C7",
    secondaryBlue: "#002F79",
    labelGray: "#746E65",
    axisGray: "#EFEFEF",

    cloudberryOrange: "#F2481B",

    textGreen: "#34AA44",
    textMagenta: "#E4005C",

    testRed: "#ff0000",
  },
};
