import React, { useState, useContext } from "react";
import { AppConfigContext } from "../../contexts/appConfigProvider";
import _ from "lodash";

import styles from "./RangeSelector.module.scss";

function RangeSelector(props) {
  const [appConfig, appConfigDispatch] = useContext(AppConfigContext);
  const [startYear, setStartYear] = useState(appConfig.startYear);
  const [startQuarter, setStartQuarter] = useState(appConfig.startQuarter);
  const [endYear, setEndYear] = useState(appConfig.endYear);
  const [endQuarter, setEndQuarter] = useState(appConfig.endQuarter);

  const getRangeLastYear = () => {
    const now = new Date();
    return now.getFullYear() + 1;
  };

  const updateSettings = () => {
    appConfigDispatch({
      type: "SET_DATE_RANGE",
      payload: {
        startYear,
        startQuarter,
        endYear,
        endQuarter,
      },
    });
  };

  return (
    <div className={styles.holder}>
      <select
        className="c-styled-select"
        value={startYear}
        onChange={(e) => {
          setStartYear(e.target.value);
        }}
      >
        {_.range(2010, getRangeLastYear()).map((value) => (
          <option key={value}>{value}</option>
        ))}
      </select>
      <select
        className="c-styled-select"
        value={startQuarter}
        onChange={(e) => {
          setStartQuarter(e.target.value);
        }}
      >
        {_.range(1, 4 + 1).map((value) => (
          <option key={value}>Q{value}</option>
        ))}
      </select>
      <span>&ndash;</span>
      <select
        className="c-styled-select"
        value={endYear}
        onChange={(e) => {
          setEndYear(e.target.value);
        }}
      >
        {_.range(2010, getRangeLastYear()).map((value) => (
          <option key={value}>{value}</option>
        ))}
      </select>
      <select
        className="c-styled-select"
        value={endQuarter}
        onChange={(e) => {
          setEndQuarter(e.target.value);
        }}
      >
        {_.range(1, 4 + 1).map((value) => (
          <option key={value}>Q{value}</option>
        ))}
      </select>
      <button onClick={updateSettings}>Calculate</button>
    </div>
  );
}

export default RangeSelector;
