import { Switch, Route } from "react-router-dom";
import React from "react";
import Dashboard from "./pages/dashboard/Dashboard";
import GPM from "./pages/gpm/GPM";

export default () => {
  return (
    <Switch>
      <Route path="/gpm" component={GPM} />
      <Route path="/" component={Dashboard} />
    </Switch>
  );
};
