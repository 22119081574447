import React from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function LoadingIndicator(props) {
  return (
    <Loader
      type="TailSpin"
      color={global.config.colors.cloudberryOrange}
      height={100}
      width={100}
      timeout={0} //3 secs
    />
  );
}

export default LoadingIndicator;
