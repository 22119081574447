import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import useLocalStorage from "./useLocalStorage";

export default (url) => {
  const baseUrl =
    document.location.href.indexOf("cloudberrycreative.com") != -1
      ? "https://react-ui.cba.cloudberrycreative.com"
      : "http://react-finances.test";
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [options, setOptions] = useState({});

  const doFetch = useCallback(
    (options = {}) => {
      setOptions(options);
      setIsLoading(true);
    },
    [url]
  );

  useEffect(() => {
    let skipGetResponseAfterDetroy = false;

    // console.log("original fetch options:", options);

    const requestOptions = {
      ...options,
      data: {
        ...options.data,
      },
    };

    // console.log("do fetch options:", requestOptions, authToken);

    if (!isLoading) {
      return;
    }

    axios(baseUrl + url, requestOptions)
      .then((res) => {
        if (!skipGetResponseAfterDetroy) {
          console.log("useFetch got result:", res.data);
          setResponse(res.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (!skipGetResponseAfterDetroy) {
          setError({ error: "Data loading error" });
          setIsLoading(false);
        }
      });

    return () => {
      //when component dismounts
      skipGetResponseAfterDetroy = true;
    };
  }, [isLoading, url, options]);

  return [{ isLoading, response, error }, doFetch];
};
