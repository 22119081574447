import React, { useEffect } from "react";
import InfoCard from "../infocard/InfoCard";
import useFetch from "../../hooks/useFetch";
import { Line } from "react-chartjs-2";
import ChartLegendItem from "../chart-legend-item/ChartLegendItem";

function DashboardFlexibilityRatio() {
  const [{ isLoading, response, error }, doFetch] = useFetch(
    "/api/flexibility-ratio.php"
  );

  const headerComponent = () => {
    return (
      <>
        <ChartLegendItem
          extraText="Current Ratio:"
          extraNumeric="TBD%"
          extraNumericColor={global.config.colors.textGreen}
        />
      </>
    );
  };

  const graphComponent = () => {
    if (!response) return <></>;
    const data = {
      labels: response.labels,
      datasets: [
        {
          label: "Ratio",
          fill: false,
          borderColor: global.config.colors.primaryBlue,
          data: response.data,
          lineTension: 0,
          borderWidth: 2,
          pointRadius: 2,
        },
      ],
    };

    const options = {
      chartArea: {
        backgroundColor: "rgba(255,255,255,1)",
      },
      layout: {
        padding: 0,
      },
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        x: {
          display: true,
          grid: {
            display: true,
            drawOnChartArea: false,
          },
          ticks: {
            fontSize: 20,
          },
        },
        y: {
          display: true,
          grid: {
            drawBorder: false,
          },
          scaleLabel: {
            display: false,
            labelString: "%",
          },
          ticks: {
            callback: function (value, index, values) {
              return value.toFixed(0) + "%";
            },
            padding: 5,
            fontSize: 8,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    };

    console.log("Building with:", data, options);

    return <Line width={350} height={170} data={data} options={options} />;
  };

  useEffect(() => {
    console.log("Fetching GPM data");
    doFetch();
  }, []);

  return (
    <InfoCard
      title="Gross Profit Margin"
      isLoading={isLoading || !response || error}
      headComponent={headerComponent()}
      graphComponent={graphComponent()}
      moreInfoLink="/"
    />
  );
}

export default DashboardFlexibilityRatio;
