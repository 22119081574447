import React from "react";
import styles from "./ChartLegendItem.module.scss";

function ChartLegendItem({
  labelText,
  labelColor,
  extraText,
  extraNumeric,
  extraNumericColor,
}) {
  return (
    <div className={styles.holder}>
      {labelText && (
        <div className={styles.left}>
          <span
            className={styles.dot}
            style={{ background: `${labelColor}` }}
          />
          <span className={styles.label}>{labelText}</span>
        </div>
      )}
      {extraText && (
        <div className={styles.right}>
          <span className={styles.label}>{extraText}</span>
          <span
            className={styles.numeric}
            style={{ color: `${extraNumericColor}` }}
          >
            {extraNumeric}
          </span>
        </div>
      )}
    </div>
  );
}

export default ChartLegendItem;
